import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Input, message, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import api from '../../../common/api';
import { OPERATIONS, TOKEN } from '../../../common/constants';
import { FormNormalize } from '../../../common/utils';
import { DELETE_ENV, DELETE_ENV_KEYS, VERIFY_ENV } from '../graphql/Mutations';
import { GET_PRIVATE_KEY_VALUE } from '../graphql/Queries';

export default function TwoFAModal({
  visible,
  setVisible,
  setValueModal,
  privateKeyValueform,
  refetchEnvKeyValueData,
  selectedRepositories,
  setActionKey,
  actionKey,
  setActionId,
  actionId,
  sourceType,
  selectedRepositorieData,
  selectedItemName,
}) {
  const [form] = Form.useForm();
  const [twoFACode, setTwoFACode] = useState(null);
  const [isWrongCode, setIsWrongCode] = useState(false);
  const otpInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('Please enter code!');

  const resetModalState = () => {
    setIsWrongCode(false);
    setVisible(false);
    setActionKey(null);
    setActionId(null);
    form?.resetFields();
  };

  const handleGraphQLSuccess = () => {
    refetchEnvKeyValueData({
      variables: {
        data: {
          refId:
            sourceType === 'GITHUB'
              ? selectedRepositorieData?.repositorieData?.name
              : selectedRepositories,
        },
      },
    });
    resetModalState();
  };

  const handleGraphQLError = (err) => {
    otpInputRef?.current?.focus();
    setTwoFACode(null);
    setIsWrongCode(true);
    form?.resetFields();
    setErrorMessage(err?.message);
    setTimeout(() => {
      otpInputRef?.current?.focus();
    }, 400);
  };

  const [viewPrivateKey] = useLazyQuery(GET_PRIVATE_KEY_VALUE, {
    onCompleted: (res) => {
      privateKeyValueform?.setFieldsValue({
        envKeyValue: res?.getPrivateKeyValue?.value,
      });
      setActionKey(null);
      setIsWrongCode(false);
      setVisible(false);
      form?.resetFields();
      setValueModal(!!actionId);
    },
    onError: handleGraphQLError,
  });

  const [deleteEnvKey, { loading: removingKey }] = useMutation(
    DELETE_ENV_KEYS,
    {
      onCompleted: handleGraphQLSuccess,
      onError: handleGraphQLError,
    },
  );

  const [deleteEnv, { loading: removingEnv }] = useMutation(DELETE_ENV, {
    onCompleted: handleGraphQLSuccess,
    onError: handleGraphQLError,
  });

  const [verifyEnv, { loading: verifyingEnv }] = useMutation(VERIFY_ENV, {
    onCompleted: handleGraphQLSuccess,
    onError: handleGraphQLError,
  });

  const handleExport = async () => {
    try {
      const syncUrl = `${
        process.env.REACT_APP_SERVER_REST_URL
      }/export-in-device/${actionId}?twoFACode=${
        twoFACode || form.getFieldValue('confirmationCode')
      }`;
      const response = await api.get(syncUrl, {
        // eslint-disable-next-line no-undef
        headers: { Authorization: `Bearer ${localStorage?.getItem(TOKEN)}` },
      });
      const blob = new Blob([response?.data], {
        type: 'application/octet-stream',
      });
      const downloadUrl = URL?.createObjectURL(blob);

      // eslint-disable-next-line no-undef
      const anchor = document?.createElement('a');
      anchor.href = downloadUrl;
      anchor.download = '.env';
      // eslint-disable-next-line no-undef
      document?.body?.appendChild(anchor);
      anchor?.click();
      // eslint-disable-next-line no-undef
      document.body.removeChild(anchor);
      URL.revokeObjectURL(downloadUrl);

      message.success('File Exported Successfully');
      resetModalState();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error during export:', error);
      handleGraphQLError(error?.response?.data);
    }
  };

  const submitAction = () => {
    const code = twoFACode || form.getFieldValue('confirmationCode');
    if (!code || code?.length < 6) {
      setErrorMessage('Please enter code!');
      setIsWrongCode(true);
      form?.resetFields();
      otpInputRef?.current?.focus();
      setTimeout(() => {
        otpInputRef?.current?.focus();
      }, 400);
      return;
    }

    switch (actionKey) {
      case OPERATIONS.VIEW_PRIVATE_KEY:
        viewPrivateKey({
          variables: {
            data: {
              twoFaCode: code,
              id: actionId,
            },
          },
        });
        break;
      case OPERATIONS.DELETE_KEY:
        deleteEnvKey({
          variables: {
            data: {
              twoFACode: code,
              keyId: actionId,
            },
          },
        });
        break;
      case OPERATIONS.DELETE_ENV:
        deleteEnv({
          variables: {
            data: {
              twoFACode: code,
              id: actionId,
            },
          },
        });
        break;
      case OPERATIONS.VERIFY_ENV:
        verifyEnv({
          variables: {
            data: {
              twoFACode: code,
              environmentId: actionId,
            },
          },
        });
        break;
      case OPERATIONS.EXPORT_ENV:
        handleExport();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (visible) {
      otpInputRef?.current?.focus();
      setTimeout(() => {
        otpInputRef?.current?.focus();
      }, 500);
    }

    const handleKeyDown = (event) => {
      if (event?.key === 'Enter' && visible) {
        event?.preventDefault();
        submitAction();
      }
    };

    // eslint-disable-next-line no-undef
    document?.addEventListener('keydown', handleKeyDown);
    return () => {
      // eslint-disable-next-line no-undef
      document?.removeEventListener('keydown', handleKeyDown);
    };
  }, [visible]);

  return (
    <Modal
      open={visible}
      centered
      title={
        actionKey === OPERATIONS.VERIFY_ENV ? (
          <div className="d-flex" title={selectedItemName}>
            Enter authentication code to verify '
            <div className="text-ellipsis modal-title"> {selectedItemName}</div>
            ' ENV
          </div>
        ) : (
          'Enter authentication code'
        )
      }
      onOk={submitAction}
      onCancel={resetModalState}
      okText="Verify"
      okButtonProps={{ loading: removingKey || removingEnv || verifyingEnv }}
    >
      <Form
        form={form}
        layout="vertical"
        className="d-flex flex-vertical m-0 justify-center text-center"
      >
        <Form.Item
          name="confirmationCode"
          className="m-0"
          normalize={FormNormalize.numberOnly}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'OTP is required',
            },
            {
              len: 6,
              message: 'Invalid input! Please enter numbers only',
            },
          ]}
        >
          <Input.OTP
            ref={otpInputRef}
            onChange={(value) => {
              setTwoFACode(value);
              form.setFieldValue('confirmationCode', value);
            }}
            status={isWrongCode ? 'error' : undefined}
            className="mt-16"
          />
        </Form.Item>
        {isWrongCode && (
          <p className="custom-error-message mt-8 m-0">{errorMessage}</p>
        )}
      </Form>
    </Modal>
  );
}
