import {
  BlockOutlined,
  DeleteOutlined,
  ExportOutlined,
  KeyOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { includes } from 'lodash';
import React from 'react';
import { OPERATIONS, ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';

export default function TableActions({
  setNewKeyModalOpen,
  setActionKey,
  setDuplicateKeyModalOpen,
  setDeleteEnvModalOpen,
  selectedKeys,
  setTwoFAModalOpen,
  setExportEnvKeysModalOpen,
  setConfirmEmptyValuesModalOpen,
  setDuplicateValuesModalOpen,
  actionId,
  actionKey,
  repoId,
  isEnvVerified,
  nullValueKeys,
  duplicateKeys,
  data,
  selectedProject,
  selectedRepositories,
  viewKeyValueModalOpen,
}) {
  const { navigate } = useRouter();

  return (
    <div className="d-flex justify-between align-center p-16">
      <div className="d-flex gap-16">
        <Button
          type="primary"
          disabled={
            !actionId ||
            !isEnvVerified ||
            includes(
              [OPERATIONS.DELETE_KEY, OPERATIONS.VIEW_PRIVATE_KEY],
              actionKey,
            ) ||
            data?.length <= 0
          }
          className="d-flex align-center"
          onClick={() => {
            setActionKey(OPERATIONS.EXPORT_ENV);
            if (nullValueKeys?.length > 0) {
              setConfirmEmptyValuesModalOpen(true);
            } else if (duplicateKeys?.length > 0) {
              setDuplicateValuesModalOpen(true);
            } else {
              setExportEnvKeysModalOpen(true);
            }
          }}
        >
          <ExportOutlined /> Export
        </Button>
        <Button
          disabled={
            viewKeyValueModalOpen ||
            !actionId ||
            isEnvVerified ||
            includes(
              [OPERATIONS.DELETE_KEY, OPERATIONS.VIEW_PRIVATE_KEY],
              actionKey,
            ) ||
            data?.length <= 0
          }
          onClick={() => {
            setActionKey(OPERATIONS.VERIFY_ENV);
            if (nullValueKeys?.length > 0) {
              setConfirmEmptyValuesModalOpen(true);
            } else if (duplicateKeys?.length > 0) {
              setDuplicateValuesModalOpen(true);
            } else {
              setTwoFAModalOpen(true);
            }
          }}
          type="primary"
          className="d-flex align-center"
        >
          <SafetyCertificateOutlined /> Verify
        </Button>
        <Button
          onClick={() => setNewKeyModalOpen(true)}
          disabled={!repoId}
          type="primary"
          className="d-flex align-center"
        >
          <KeyOutlined /> Add Key
        </Button>
        <Button
          onClick={() => setDuplicateKeyModalOpen(true)}
          disabled={
            selectedKeys?.length <= 0 ||
            data?.[0]?.environmentKeyValues?.length <= 1
          }
          type="primary"
          className="d-flex align-center"
        >
          <BlockOutlined /> Copy Value/s
        </Button>
        <Button
          onClick={() => setDeleteEnvModalOpen(true)}
          disabled={
            viewKeyValueModalOpen ||
            !actionId ||
            includes(
              [OPERATIONS.DELETE_KEY, OPERATIONS.VIEW_PRIVATE_KEY],
              actionKey,
            )
          }
          type="primary"
          className="d-flex align-center"
          danger
        >
          <DeleteOutlined /> Delete Env.
        </Button>
        <Button
          type="primary"
          disabled={!repoId}
          onClick={() => {
            navigate(ROUTES.HISTORY, {
              state: {
                projectData: selectedProject,
                repositoryId: selectedRepositories,
              },
            });
          }}
        >
          History
        </Button>
      </div>
      <div className="text-center">Logicwind © {new Date().getFullYear()}</div>
    </div>
  );
}
