import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { FormNormalize } from '../../common/utils';
import MobileLogoComponent from '../../components/MobileLogoComponent';
import useRouter from '../../hooks/useRouter';
import { VERIFY_TWO_FA } from './graphql/Mutations';

function Verification() {
  const [form] = Form.useForm();
  const { initializeAuth, dispatch } = useContext(AppContext);
  const {
    location: { state },
    navigate,
  } = useRouter();
  const otpInputRef = useRef(null);

  const [isWrongCode, setIsWrongCode] = useState(false);

  const { accessToken, refreshToken, userData, isTwoFaEnabled, twoFaObj } =
    state || {};

  const [verifyTwoFA, { loading: verifyLoading }] = useMutation(VERIFY_TWO_FA, {
    context: {
      token: accessToken,
    },
    onError: () => {
      setIsWrongCode(true);
      form?.resetFields();
      otpInputRef?.current?.focus();
      setTimeout(() => {
        otpInputRef?.current?.focus();
      }, 400);
    },
    onCompleted: () => {
      initializeAuth(
        accessToken,
        userData,
        refreshToken,
        isTwoFaEnabled,
        twoFaObj,
      );
      dispatch({
        type: 'SET_TWO_FA_VERIFIED',
        data: true,
      });
      navigate(ROUTES?.MAIN);
    },
  });

  const onFinish = (values) => {
    verifyTwoFA({
      variables: {
        data: {
          code: values?.confirmationCode,
        },
      },
    });
  };

  useEffect(() => {
    otpInputRef?.current?.focus();
    if (!state) {
      navigate(ROUTES?.LOGIN, { replace: true });
    }
  }, [state]);

  return (
    accessToken && (
      <div className="auth-bg">
        <div className="login-wrap d-flex align-center justify-start">
          <Card className="full-width">
            <Spin spinning={verifyLoading} wrapperClassName="full-width">
              <div id="logo" className="header-wrapper text-center">
                <MobileLogoComponent className="mr-8" />
                <div className="mt-8">
                  <h3>LW Vault</h3>
                </div>
              </div>
              {isTwoFaEnabled ? (
                <div>
                  <div className="d-flex flex-vertical align-center gap-8 mt-16">
                    <p className="m-0">{state?.userData?.email}</p>
                    <Form
                      form={form}
                      layout="vertical"
                      className="confirmation-form"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        label="Enter the 6 figure confirmation code shown on the app"
                        name="confirmationCode"
                        normalize={FormNormalize.numberOnly}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'OTP is required',
                          },
                          {
                            len: 6,
                            message: 'Invalid input! Please enter numbers only',
                          },
                        ]}
                      >
                        <Input.OTP
                          size="large"
                          ref={otpInputRef}
                          status={isWrongCode ? 'error' : undefined}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                          Verify
                        </Button>
                      </Form.Item>
                    </Form>
                    <p className="m-0">
                      Want to change account ?
                      <Button
                        type="link"
                        onClick={() =>
                          navigate(ROUTES?.LOGIN, { replace: true })
                        }
                      >
                        Login
                      </Button>
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="d-flex flex-vertical align-center gap-8">
                    <h3>Set Two-factor Authentication</h3>
                    {state?.twoFaObj?.otpauthUrl && (
                      <QRCode value={state?.twoFaObj?.otpauthUrl} size={150} />
                    )}
                    <Typography.Text>
                      Scan the QR code using any authentication application on
                      your phone (e.g., Google Authenticator, Authy, Keeper,
                      etc.) or enter the following code:
                    </Typography.Text>
                    <Typography.Paragraph copyable className="auth-code">
                      {state?.twoFaObj?.otpauthUrl?.split('=')?.[1]}
                    </Typography.Paragraph>
                    <p className="m-0">{state?.userData?.email}</p>
                    <Form
                      form={form}
                      layout="vertical"
                      className="confirmation-form"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        label="Enter the 6 figure confirmation code shown on the app:"
                        name="confirmationCode"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'OTP is required',
                          },
                          {
                            len: 6,
                            message: 'Invalid input! Please enter numbers only',
                          },
                        ]}
                      >
                        <Input.OTP
                          size="large"
                          ref={otpInputRef}
                          status={isWrongCode ? 'error' : undefined}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                          Verify
                        </Button>
                      </Form.Item>
                    </Form>
                    <p className="m-0">
                      Want to change account ?
                      <Button
                        type="link"
                        onClick={() =>
                          navigate(ROUTES?.LOGIN, { replace: true })
                        }
                      >
                        Login
                      </Button>
                    </p>
                  </div>
                </div>
              )}
            </Spin>
          </Card>
          <div className="text-center login-copyrights">
            Logicwind © {new Date()?.getFullYear()}
          </div>
        </div>
      </div>
    )
  );
}

export default Verification;
