import {
  LockOutlined,
  SafetyCertificateFilled,
  WarningOutlined,
} from '@ant-design/icons';
import { Card, Checkbox, Form, Tooltip } from 'antd';

import { useLazyQuery, useMutation } from '@apollo/client';
import { uniq } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  OPERATIONS,
  PROJECT_DATA,
  REPOSITORY_DATA,
  ROUTES,
  SOURCE_TYPE,
} from '../../common/constants';
import Portal from '../../components/Portal';
import useRouter from '../../hooks/useRouter';
import ProjectBranchSelection from './components/ProjectBranchSelection';
import ProjectEnvViewTable from './components/ProjectEnvViewTable';
import TableActions from './components/TableActions';
import './dashboard.less';
import { UPDATE_ENV_KEY_VALUE } from './graphql/Mutations';
import { GET_KEY_VALUE_LIST } from './graphql/Queries';
import AddKeyModal from './modals/AddKeyModal';
import ConfirmDuplicateValuesModal from './modals/ConfirmDuplicateValuesModal';
import ConfirmEmptyValuesModal from './modals/ConfirmEmptyValuesModal';
import DeleteEnvModal from './modals/DeleteEnvModal';
import DeleteKeyModal from './modals/DeleteKeyModal';
import DuplicateKeyValueModal from './modals/DuplicateKeyValueModal';
import ExportEnvKeysModal from './modals/ExportEnvModal';
import TwoFAModal from './modals/TwoFAModal';
import ViewKeyValueModal from './modals/ViewKeyValueModal';

const defaultColumns = [
  {
    title: 'Sr. No.',
    dataIndex: 'key',
    width: 40,
    fixed: 'left',
    // eslint-disable-next-line no-return-assign
    render: (_, __, index) => (
      // eslint-disable-next-line no-param-reassign
      <div className="custom-column">{(index += 1)}</div>
    ),
  },
  {
    title: 'Key',
    dataIndex: 'environmentKey',
    key: 'environmentKey',
    width: 240,
    fixed: 'left',
    render: (value, record) => (
      <div className="align-center gap-24">
        <div className="d-flex flex-horizontal gap-8 lock-icon" title={value}>
          <div className="text-ellipsis key-column">{value}</div>
          {record?.isPrivate && <LockOutlined />}
        </div>
      </div>
    ),
  },
];

function Dashboard() {
  const {
    navigate,
    location: { state },
  } = useRouter();
  const { setRepositoryId, isTwoFaSet, fetchTwoFaKey } = useContext(AppContext);
  const [privateKeyValueform] = Form.useForm();
  const isVerified = isTwoFaSet();
  const twoFaKey = fetchTwoFaKey();

  const [newKeyModalOpen, setNewKeyModalOpen] = useState(false);
  const [deleteEnvModalOpen, setDeleteEnvModalOpen] = useState(false);
  const [viewKeyValueModalOpen, setViewKeyValueModalOpen] = useState(false);
  const [twoFAModalOpen, setTwoFAModalOpen] = useState(false);
  const [duplicateKeyModalOpen, setDuplicateKeyModalOpen] = useState(false);
  const [exportEnvKeysModalOpen, setExportEnvKeysModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRepositories, setSelectedRepositories] = useState(
    state?.repositoryId ||
      // eslint-disable-next-line no-undef
      JSON.parse(localStorage?.getItem(REPOSITORY_DATA))?.value ||
      null,
  );
  const [selectedProject, setSelectedProject] = useState(
    state?.projectData ||
      // eslint-disable-next-line no-undef
      JSON.parse(localStorage?.getItem(PROJECT_DATA)) ||
      null,
  );
  const [repoId, setRepoId] = useState(null);
  const [deleteKeyModalOpen, setDeleteKeyModalOpen] = useState(false);
  const [repoEnvs, setRepoEnvs] = useState([]);
  const [isEnvVerified, setIsEnvVerified] = useState(false);
  const [sourceType, setSourceType] = useState(
    // eslint-disable-next-line no-undef
    localStorage?.getItem(SOURCE_TYPE) || null,
  );

  const [actionId, setActionId] = useState(null);
  const [actionKey, setActionKey] = useState(null);
  const [data, setData] = useState();
  const [selectedRepositorieData, setSelectedRepositorieData] = useState(
    // eslint-disable-next-line no-undef
    JSON?.parse(localStorage?.getItem(REPOSITORY_DATA)) || null,
  );
  const [envColumns, setEnvColumns] = useState([...defaultColumns]);
  const [selectedItemName, setSelectedItemName] = useState('');
  const [
    confirmEmptyValuesModalOpen,
    setConfirmEmptyValuesModalOpen,
  ] = useState(false);
  const [duplicateValuesModalOpen, setDuplicateValuesModalOpen] = useState(
    false,
  );
  const [duplicateKeys, setDuplicateKeys] = useState([]);
  const [nullValueKeys, setNullValueKeys] = useState([]);

  const handleCheckboxChange = (env) => {
    setIsEnvVerified(env?.isVerified);
    setActionId((prev) => (prev === env.id ? '' : env.id));
    setSelectedItemName(env?.name);
  };

  const renderNewColumns = useCallback(
    (environments) =>
      environments?.map((env) => ({
        title: (
          <div className="d-flex justify-between">
            <div className="d-flex align-center gap-8 verified-env">
              <Checkbox
                checked={actionId === env?.id}
                onChange={() => handleCheckboxChange(env)}
              />
              <span className="m-0 text-left">{env?.name}</span>
              {env?.isVerified && <SafetyCertificateFilled />}
            </div>
          </div>
        ),
        dataIndex: env?.name,
        key: env?.name,
        editable: true,
        ellipsis: true,
        render: (_, record) => {
          // Extract the value of the current column
          const columnToSetValue = record?.environmentKeyValues?.find(
            (envName) => env?.name === envName?.environmentName,
          );

          const currentValue = columnToSetValue?.environmentKeyValue || null;

          // Group values for uniqueness check
          const groupedValues = record?.environmentKeyValues.reduce(
            (acc, item) => {
              const keyValue = item.environmentKeyValue || null;
              if (!acc[keyValue]) {
                acc[keyValue] = [];
              }
              acc[keyValue].push(item.environmentName);
              return acc;
            },
            {},
          );

          const isUniqueValue = groupedValues[currentValue]?.length === 1;

          const tooltipContent = groupedValues[currentValue]?.map((name) => (
            <div key={name}>{name}</div>
          ));

          return (
            <div className="d-flex align-center justify-between">
              <div
                className="d-flex align-center"
                title={columnToSetValue?.environmentKeyValue}
              >
                {!record?.isPrivate && !isUniqueValue && currentValue && (
                  <Tooltip
                    title={tooltipContent}
                    className="duplicate-value-info"
                  >
                    <WarningOutlined className="cell-warning-icon" />
                  </Tooltip>
                )}
                {columnToSetValue?.environmentKeyValue || '-'}
              </div>
            </div>
          );
        },
      })),
    [actionId],
  );

  const [
    fetchKeyValues,
    { data: envData, refetch: refetchEnvKeyValueData, loading },
  ] = useLazyQuery(GET_KEY_VALUE_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setRepositoryId(res?.getKeyValueList?.repositoryId);
      setRepoId(res?.getKeyValueList?.repositoryId);
      setRepoEnvs(res?.getKeyValueList?.environments);
      setData([...res?.getKeyValueList?.keys]);
    },
    onError: () => {},
  });

  useEffect(() => {
    if (isVerified && JSON.parse(isVerified)) {
      navigate(ROUTES.MAIN, {
        replace: true,
        state: {
          projectData:
            state?.projectData ||
            // eslint-disable-next-line no-undef
            JSON.parse(localStorage?.getItem(PROJECT_DATA)),
          repositoryId: state?.repositoryId,
        },
      });
    } else {
      navigate(ROUTES?.VERIFY, { state: twoFaKey });
    }
  }, []);

  useEffect(() => {
    if (!envData?.getKeyValueList?.environments) return;

    if (selectedRepositories) {
      setEnvColumns([
        ...defaultColumns,
        ...renderNewColumns(envData?.getKeyValueList?.environments),
      ]);
    } else {
      setEnvColumns([...defaultColumns]);
      setData([]);
    }
  }, [actionId, envData, selectedRepositories]);

  const [updateEnvKeyValue] = useMutation(UPDATE_ENV_KEY_VALUE, {
    onCompleted() {
      fetchKeyValues({
        variables: {
          data: {
            refId:
              sourceType === 'GITHUB'
                ? selectedRepositorieData?.repositorieData?.name
                : selectedRepositories,
          },
        },
      });
    },
    onError() {},
  });

  useEffect(() => {
    if (actionKey !== OPERATIONS.DELETE_KEY) {
      const keysToPushInArray = data?.filter((env) =>
        env?.environmentKeyValues?.some(
          (envName) =>
            actionId === envName?.environmentId &&
            envName?.environmentKeyValue === '',
        ),
      );

      const envKeyNames = keysToPushInArray?.map((obj) => obj?.environmentKey);
      setNullValueKeys(envKeyNames);

      // Group environmentKeyValues by value to find duplicates
      const duplicateGroups = data?.reduce((acc, env) => {
        env?.environmentKeyValues?.forEach((envName) => {
          if (
            envName?.environmentId === actionId &&
            envName?.environmentKeyValue !== ''
          ) {
            const value = envName?.environmentKeyValue || null;
            if (!acc[value]) {
              acc[value] = [];
            }
            acc[value]?.push(env?.environmentKey);
          }
        });
        return acc;
      }, {});

      const duplicateKeyNames = Object?.values(duplicateGroups || {})
        ?.filter((keys) => keys?.length > 1)
        ?.flat();
      setDuplicateKeys(uniq(duplicateKeyNames) || []);
    }
  }, [actionId, actionKey, data]);

  return (
    <>
      <Portal portalId="header-title-content">
        <ProjectBranchSelection
          selectedProject={selectedProject}
          selectedRepositories={selectedRepositories}
          setSelectedRepositories={setSelectedRepositories}
          setSelectedProject={setSelectedProject}
          refetchEnvKeyValueData={refetchEnvKeyValueData}
          repoEnvs={repoEnvs}
          setSourceType={setSourceType}
          sourceType={sourceType}
          selectedRepositorieData={selectedRepositorieData}
          setSelectedRepositorieData={setSelectedRepositorieData}
          setRepoId={setRepoId}
        />
      </Portal>
      <div className="table-card-page">
        <Card className="ant-body-scroll">
          <div className="d-flex flex-vertical justify-between flex-1">
            <div>
              <ProjectEnvViewTable
                data={data}
                selectedRowKeys={selectedRowKeys}
                selectedRepositories={selectedRepositories}
                selectedRepositorieData={selectedRepositorieData}
                sourceType={sourceType}
                fetchKeyValues={fetchKeyValues}
                envColumns={envColumns}
                setSelectedRowKeys={setSelectedRowKeys}
                setTwoFAModalOpen={setTwoFAModalOpen}
                setDeleteKeyModalOpen={setDeleteKeyModalOpen}
                setActionKey={setActionKey}
                setActionId={setActionId}
                updateEnvKeyValue={updateEnvKeyValue}
                loading={loading}
                setSelectedItemName={setSelectedItemName}
              />
            </div>
            <div>
              <TableActions
                data={data}
                setNewKeyModalOpen={setNewKeyModalOpen}
                setActionKey={setActionKey}
                setTwoFAModalOpen={setTwoFAModalOpen}
                setExportEnvKeysModalOpen={setExportEnvKeysModalOpen}
                setDuplicateKeyModalOpen={setDuplicateKeyModalOpen}
                setDeleteEnvModalOpen={setDeleteEnvModalOpen}
                selectedKeys={selectedRowKeys}
                actionId={actionId}
                actionKey={actionKey}
                repoId={repoId}
                isEnvVerified={isEnvVerified}
                setConfirmEmptyValuesModalOpen={setConfirmEmptyValuesModalOpen}
                confirmEmptyValuesModalOpen={confirmEmptyValuesModalOpen}
                setDuplicateValuesModalOpen={setDuplicateValuesModalOpen}
                duplicateValuesModalOpen={duplicateValuesModalOpen}
                nullValueKeys={nullValueKeys}
                duplicateKeys={duplicateKeys}
                selectedProject={selectedProject}
                selectedRepositories={selectedRepositories}
                viewKeyValueModalOpen={viewKeyValueModalOpen}
              />
            </div>
          </div>
        </Card>
      </div>
      <ConfirmEmptyValuesModal
        visible={confirmEmptyValuesModalOpen}
        setVisible={setConfirmEmptyValuesModalOpen}
        setTwoFaModalVisible={setTwoFAModalOpen}
        setActionId={setActionId}
        setActionKey={setActionKey}
        selectedItemName={selectedItemName}
        nullValueKeys={nullValueKeys}
        actionKey={actionKey}
        setExportEnvKeysModalOpen={setExportEnvKeysModalOpen}
        setDuplicateValuesModalOpen={setDuplicateValuesModalOpen}
        duplicateKeys={duplicateKeys}
      />
      <ConfirmDuplicateValuesModal
        visible={duplicateValuesModalOpen}
        setVisible={setDuplicateValuesModalOpen}
        setActionKey={setActionKey}
        setTwoFaModalVisible={setTwoFAModalOpen}
        setActionId={setActionId}
        selectedItemName={selectedItemName}
        actionKey={actionKey}
        setExportEnvKeysModalOpen={setExportEnvKeysModalOpen}
        duplicateKeys={duplicateKeys}
      />
      <DeleteKeyModal
        visible={deleteKeyModalOpen}
        setVisible={setDeleteKeyModalOpen}
        setActionKey={setActionKey}
        setTwoFaModalVisible={setTwoFAModalOpen}
        setActionId={setActionId}
        selectedItemName={selectedItemName}
      />
      <AddKeyModal
        visible={newKeyModalOpen}
        setVisible={setNewKeyModalOpen}
        repoId={repoId}
        selectedRepositories={selectedRepositories}
        refetchEnvKeyValueData={refetchEnvKeyValueData}
        sourceType={sourceType}
        selectedRepositorieData={selectedRepositorieData}
      />
      {twoFAModalOpen && (
        <TwoFAModal
          visible={twoFAModalOpen}
          setVisible={setTwoFAModalOpen}
          setValueModal={setViewKeyValueModalOpen}
          privateKeyValueform={privateKeyValueform}
          refetchEnvKeyValueData={refetchEnvKeyValueData}
          selectedRepositories={selectedRepositories}
          actionId={actionId}
          actionKey={actionKey}
          setActionKey={setActionKey}
          setActionId={setActionId}
          sourceType={sourceType}
          selectedRepositorieData={selectedRepositorieData}
          selectedItemName={selectedItemName}
        />
      )}
      <ViewKeyValueModal
        visible={viewKeyValueModalOpen}
        setVisible={setViewKeyValueModalOpen}
        privateKeyValueform={privateKeyValueform}
        updateEnvKeyValue={updateEnvKeyValue}
        actionId={actionId}
        setActionId={setActionId}
        setActionKey={setActionKey}
      />
      <DuplicateKeyValueModal
        visible={duplicateKeyModalOpen}
        setVisible={setDuplicateKeyModalOpen}
        selectedKeys={selectedRowKeys}
        envRecords={envData?.getKeyValueList?.environments}
        refetchEnvKeyValueData={refetchEnvKeyValueData}
        selectedRepositories={selectedRepositories}
        setSelectedRowKeys={setSelectedRowKeys}
        sourceType={sourceType}
        selectedRepositorieData={selectedRepositorieData}
      />
      <DeleteEnvModal
        visible={deleteEnvModalOpen}
        setVisible={setDeleteEnvModalOpen}
        setTwoFAModalOpen={setTwoFAModalOpen}
        setActionKey={setActionKey}
        selectedItemName={selectedItemName}
      />
      <ExportEnvKeysModal
        visible={exportEnvKeysModalOpen}
        setVisible={setExportEnvKeysModalOpen}
        setTwoFAModalOpen={setTwoFAModalOpen}
        selectedItemName={selectedItemName}
      />
    </>
  );
}

export default Dashboard;
