import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Form, Input, Popover, Table } from 'antd';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { OPERATIONS } from '../../../common/constants';

// Editable cell code starts here
const EditableContext = React.createContext(null);

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  setTwoFAModalOpen,
  setActionKey,
  setActionId,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const formContext = useContext(EditableContext);
  let childNode = children;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    const keyValue = record?.environmentKeyValues?.find(
      (env) => dataIndex === env?.environmentName,
    );
    formContext.setFieldsValue({
      [dataIndex]: keyValue?.environmentKeyValue,
    });
  };

  const save = async () => {
    try {
      const values = await formContext.validateFields();
      toggleEdit();
      if (!isEmpty(values[dataIndex])) {
        handleSave({
          ...record,
          ...values,
        });
      } else {
        handleSave({
          ...record,
          [dataIndex]: '-',
        });
      }
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log('Save failed:', errInfo);
    }
  };

  const handleMenuClick = (key) => {
    if (key === 'edit') {
      toggleEdit();
    }
    if (key === 'view') {
      const privateKeyValueId = record?.environmentKeyValues?.find(
        (env) => dataIndex === env?.environmentName,
      );
      setTwoFAModalOpen(true);
      setActionKey(OPERATIONS.VIEW_PRIVATE_KEY);
      setActionId(privateKeyValueId?.environmentkeyValueId);
    }
  };

  const menu = (
    <div className="d-flex flex-vertical text-btn">
      {record?.isPrivate ? (
        <Button
          size="small"
          onClick={() => handleMenuClick('view')}
          type="text"
        >
          View
        </Button>
      ) : (
        <Button
          size="small"
          onClick={() => handleMenuClick('edit')}
          type="text"
          className="p-12"
        >
          Edit
        </Button>
      )}
    </div>
  );

  const handleOnBlur = (value) => {
    if (
      value !==
      record?.environmentKeyValues?.find(
        (env) => dataIndex === env?.environmentName,
      )?.environmentKeyValue
    ) {
      save();
    } else {
      setEditing(false);
    }
  };

  if (editable) {
    childNode = editing ? (
      <Form.Item className="m-0" name={dataIndex}>
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={(e) => handleOnBlur(e?.target?.value)}
        />
      </Form.Item>
    ) : (
      <div className="d-flex align-center justify-between">
        <div title={children} className="editable-cell">
          {children}
        </div>
        <Popover
          content={menu}
          trigger="click"
          placement="bottomRight"
          rootClassName="action-button-popover"
        >
          <MoreOutlined className="pointer m-8" />
        </Popover>
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
// Editable cell code ends here

export default function ProjectEnvViewTable({
  data,
  selectedRowKeys,
  selectedRepositories,
  fetchKeyValues,
  envColumns,
  setSelectedRowKeys,
  setTwoFAModalOpen,
  setDeleteKeyModalOpen,
  setActionKey,
  setActionId,
  updateEnvKeyValue,
  loading,
  sourceType,
  selectedRepositorieData,
  setSelectedItemName,
}) {
  const [form] = Form.useForm();

  const handleSave = (dataIndex, row, record) => {
    const prevValue = record?.environmentKeyValues?.filter(
      (item) => item?.environmentName === dataIndex,
    );

    const isSameValue = prevValue?.[0]?.environmentKeyValue === row[dataIndex];

    if (isSameValue) return;

    const filteredEnv = row?.environmentKeyValues?.find(
      (env) => env?.environmentName === dataIndex,
    );
    updateEnvKeyValue({
      variables: {
        data: {
          id: filteredEnv?.environmentkeyValueId,
          value: row[dataIndex],
        },
      },
    });
  };

  useEffect(() => {
    if (selectedRepositories) {
      fetchKeyValues({
        variables: {
          data: {
            refId:
              sourceType === 'GITHUB'
                ? selectedRepositorieData?.repositorieData?.name
                : selectedRepositories,
          },
        },
      });
    }
  }, [selectedRepositories]);

  const EditableCellWithCustomProps = (props) => (
    <EditableCell
      {...props}
      setTwoFAModalOpen={setTwoFAModalOpen}
      setActionId={setActionId}
      setActionKey={setActionKey}
    />
  );

  const components = {
    body: {
      cell: EditableCellWithCustomProps,
    },
  };

  const finalColumns = [
    ...envColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: (row) => handleSave(col?.dataIndex, row, record),
        }),
      };
    }),
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 90,
      render: (_, record) => (
        <div className="custom-column">
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteKeyModalOpen(true);
              setActionId(record?.environmentKeyId);
              setActionKey(OPERATIONS.DELETE_KEY);
              setSelectedItemName(record?.environmentKey);
            }}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <EditableContext.Provider value={form}>
        <Form form={form} component={false}>
          <div className="record-table">
            <Table
              size="small"
              bordered
              components={components}
              columns={finalColumns}
              dataSource={data}
              loading={loading}
              rowKey={(record) => record?.environmentKeyId}
              rowSelection={rowSelection}
              rowClassName={() => 'editable-row'}
              pagination={false}
              scroll={{ y: 'calc(100vh - 190px)' }}
            />
          </div>
        </Form>
      </EditableContext.Provider>
    </>
  );
}
